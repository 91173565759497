.root {
  /* Layout */
  display: flex;
  width: 100%;
  margin: auto;
  position: relative;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
/* Aspect ratio for is given inline */
.aspectPadding {}

.aspectBox {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: top;
  background-color: white;
}

/* New rule for the image inside the aspectBox to control its size */
.aspectBox img {
  max-width: 510px;
  max-height: 680px;
  height: 100%;
  object-fit: contain;
}